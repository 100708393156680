export default {
	data: {
		selectedImageIndex: undefined
	},
	beforeMount: function () {
		console.log('beforeMount@static');
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	mounted: function () {
		var url = window.location.href;
		console.log('mounted@url::' + url);
		if (url && url.indexOf('#') > 0) {
			var section = url.substring(url.indexOf('#') + 1);
			console.log('section:::' + section);
			setTimeout(function () {
				$('html, body').animate({
					scrollTop: $("#" + section).offset().top - 250
				}, 0);
			}, 500);


		}
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	}

};